"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INLINES = void 0;
/**
 * Map of all Contentful inline types. Inline contain inline or text nodes.
 */
var INLINES;
(function (INLINES) {
    INLINES["HYPERLINK"] = "hyperlink";
    INLINES["ENTRY_HYPERLINK"] = "entry-hyperlink";
    INLINES["ASSET_HYPERLINK"] = "asset-hyperlink";
    INLINES["EMBEDDED_ENTRY"] = "embedded-entry-inline";
})(INLINES = exports.INLINES || (exports.INLINES = {}));
