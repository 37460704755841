"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var blocks_1 = require("./blocks");
/**
 * A rich text document considered to be empty.
 * Any other document structure than this is not considered empty.
 */
var EMPTY_DOCUMENT = {
    nodeType: blocks_1.BLOCKS.DOCUMENT,
    data: {},
    content: [
        {
            nodeType: blocks_1.BLOCKS.PARAGRAPH,
            data: {},
            content: [
                {
                    nodeType: 'text',
                    value: '',
                    marks: [],
                    data: {},
                },
            ],
        },
    ],
};
exports.default = EMPTY_DOCUMENT;
